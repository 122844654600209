import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { Subdomain } from '@regas/bruce';
import { OriginService } from '@regas/shared';
import { LoaderService } from '../../services/loading/loading.service';
import { LocalStorageKeys } from '../../utils/storage-manager/local-storage-keys';

interface IdpStatusResponse {
  status: 'ENABLED' | 'MIGRATING' | 'DISABLED';
  url?: string;
}

@Injectable({
  providedIn: 'root',
})
export class IdpRedirectGuard implements CanActivate {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly originService: OriginService,
    private readonly loaderService: LoaderService,
    private readonly router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const substitution = route.paramMap.get('substitution') as string;

    localStorage.setItem(LocalStorageKeys.SUBSTITUTION, substitution);

    this.loaderService.showLoader();

    return this.checkSubstitution(substitution).pipe(
      map(({ status, url }) => {
        if (status === 'ENABLED' && url) {
          const goto = route.queryParams?.['goto'];
          if (goto) {
            window.location.href = `${url}?goto=${goto}`;
          } else {
            window.location.href = url;
          }
          return false;
        }
        this.loaderService.hideLoader();
        return true;
      }),
      catchError(() => this.router.navigate(['/idp-error'])),
      finalize(() => this.loaderService.hideLoader()),
    );
  }

  private checkSubstitution(
    substitution: string,
  ): Observable<IdpStatusResponse> {
    return this.httpClient.get<IdpStatusResponse>(
      this.originService.getUrl(Subdomain.Api, `myneva-idp/${substitution}`)
        .href,
    );
  }
}
