import { Routes } from '@angular/router';
import { IdpRedirectGuard } from '../guard/idp-redirect.guard';
import { ClientLoginComponent } from './client-login.component';

export const clientLoginRouting: Routes = [
  {
    path: 'client/:substitution',
    component: ClientLoginComponent,
    canActivate: [IdpRedirectGuard],
  },
  {
    path: 'client',
    component: ClientLoginComponent,
  },
];
