import { Routes } from '@angular/router';
import { IdpRedirectGuard } from '../guard/idp-redirect.guard';
import { UserLoginComponent } from './user-login.component';

export const userLoginRouting: Routes = [
  {
    path: ':substitution',
    component: UserLoginComponent,
    canActivate: [IdpRedirectGuard],
  },
];
