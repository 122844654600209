import { Routes } from '@angular/router';
import { AutologoutComponent } from '../logout/autologout.component';
import { ssoRedirectionRouting } from '../sso-redirection-view/sso.routing';
import { clientLoginRouting } from './client-login/client-login.routing';
import { userLoginRouting } from './user-login/user-login.routing';
import { ssoRouting } from './sso/sso.routing';
import { IdpErrorComponent } from './idp-error/idp-error.component';

export const loginRouting: Routes = [
  {
    path: 'logout',
    component: AutologoutComponent,
  },
  { path: 'idp-error', component: IdpErrorComponent },
  { path: 'idp-error/:errorCode', component: IdpErrorComponent },
  ...ssoRouting,
  ...ssoRedirectionRouting,
  ...userLoginRouting,
  ...clientLoginRouting,
];
