import { Injectable } from '@angular/core';
import { SessionStorageKeys } from '../../../utils/storage-manager/session-storage-keys';
import { SsoError } from '../../../models/SsoError';

export enum SsoErrorCode {
  SSO,
  IP,
  OIDC_IP,
}

@Injectable({
  providedIn: 'root',
})
export class SsoErrorService {
  protected static readonly ERROR_CODE_TO_MESSAGE = new Map<
    SsoErrorCode,
    string
  >([
    [SsoErrorCode.SSO, 'error.sso.loginFailed'],
    [SsoErrorCode.IP, 'error.ipRestricted'],
  ]);

  public save(errorCode: string | undefined, incidentId = ''): void {
    let backendErrorCode = SsoErrorCode.SSO;
    if (
      errorCode &&
      Object.keys(SsoErrorCode).indexOf(errorCode.toUpperCase()) !== -1
    ) {
      backendErrorCode =
        SsoErrorCode[errorCode.toUpperCase() as keyof typeof SsoErrorCode];
    }

    const errorMessage: string = SsoErrorService.ERROR_CODE_TO_MESSAGE.get(
      backendErrorCode,
    ) as string;
    this.storeToStorage({
      message: errorMessage,
      incidentId: incidentId,
    });
  }

  public readAndDelete(): SsoError | undefined {
    const item = sessionStorage.getItem(SessionStorageKeys.SSO_ERROR);
    if (!item) {
      return undefined;
    }

    const ssoError = JSON.parse(item) as SsoError;
    this.clearStorage();
    return ssoError;
  }

  private storeToStorage(ssoError: SsoError): void {
    sessionStorage.setItem(
      SessionStorageKeys.SSO_ERROR,
      JSON.stringify(ssoError),
    );
  }
  private clearStorage(): void {
    sessionStorage.removeItem(SessionStorageKeys.SSO_ERROR);
  }
}
