import { Component, Injector } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  PhoneNumber,
  PhoneNumberType,
  PhoneNumberUtil,
} from 'google-libphonenumber';
import { BaseInputComponent } from '../base-input.component';
import { Regex } from '../../../regex';
import { Phone } from '../../../../models/Phone';
import { Option } from '../../../../models/Option';
import { PhoneFormatter } from '../../../../utils/phone-formatter/phone-formatter';

@Component({
  selector: 'regas-client-phone-input',
  templateUrl: './client-phone-input.component.html',
  styleUrls: ['./client-phone-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: ClientPhoneInputComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: ClientPhoneInputComponent,
    },
  ],
})
export class ClientPhoneInputComponent extends BaseInputComponent {
  private prefix: string;
  // must be higher than 1
  private readonly VALID_CHARACTERS_LIMIT = 2;
  readonly languageOptions: Option[] = [
    {
      path: 'assets/icons/flags/nl.svg',
      abbreviation: 'nlNl',
      name: '31',
      country: 'NL',
    },
    {
      path: 'assets/icons/flags/gb.svg',
      abbreviation: 'enGb',
      name: '44',
      country: 'GB',
    },
    {
      path: 'assets/icons/flags/be.svg',
      abbreviation: 'be',
      name: '32',
      country: 'BE',
    },
    {
      path: 'assets/icons/flags/pl.svg',
      abbreviation: 'pl',
      name: '48',
      country: 'PL',
    },
    {
      path: 'assets/icons/flags/de.svg',
      abbreviation: 'deDe',
      name: '49',
      country: 'DE',
    },
  ];

  constructor(injector: Injector) {
    super(injector);
  }

  onKeyDown(event: KeyboardEvent): boolean {
    return (
      Regex.digit.test(event.key) ||
      event.key === 'Backspace' ||
      event.key === 'Tab'
    );
  }

  getInputCssClass(): string {
    return this.inputCssClass;
  }

  onPrefixChange = (prefix: string): void => {
    this.prefix = prefix;
    const phone: Phone = {
      number: PhoneFormatter.getE164NumberFormat(
        this.prefix,
        (this.value as Phone).number as string,
      ),
      prefix: this.prefix,
    };
    this.writeValue(phone);
    this.control.setValue(phone);
    this.control?.updateValueAndValidity();
  };

  writeValue(value: string | object): void {
    typeof value === 'string'
      ? super.writeValue({
          number: PhoneFormatter.getE164NumberFormat(this.prefix, value),
          prefix: this.prefix,
        })
      : super.writeValue(value);
  }

  isRequiredValuePresent = (): boolean => !!(this.value as Phone).number;

  validateInput = (phone: Phone): boolean => {
    const country = this.languageOptions.find(
      phoneOption => phone.prefix === phoneOption.name,
    )?.country;
    if (
      !country ||
      !phone.number ||
      phone?.number.length < this.VALID_CHARACTERS_LIMIT ||
      !Regex.digit.test(phone.number)
    ) {
      return false;
    } else {
      const phoneNumberUtil = PhoneNumberUtil.getInstance();
      let phoneNumber: PhoneNumber;
      try {
        phoneNumber = phoneNumberUtil.parseAndKeepRawInput(
          PhoneFormatter.getFullPhoneNumber(phone),
        );
      } catch (error) {
        return false;
      }
      return (
        phoneNumberUtil.isValidNumber(phoneNumber) &&
        phoneNumberUtil.getNumberType(phoneNumber) === PhoneNumberType.MOBILE &&
        this.validatePhoneNumberWithZeroAsFirstNumber(phone)
      );
    }
  };

  private validatePhoneNumberWithZeroAsFirstNumber(phone: Phone): boolean {
    return phone.number && phone.number?.length > 0
      ? phone.number[0] !== '0'
      : true;
  }
}
